<template>
  <div>

    <div class="contentFrame">
      <div class="leftSide">
        
        
      <div class="subinfotext">プライバシーポリシー<br>
(個人情報の取り扱いについて)</div>
      
      
   <div class="sentent_g1">
     <h2>１． プライバシーについて</h2>
株式会社スカットコミュニケーションズ（以下、「当社」といいます。）は、当社のウェブサイト（以下、本 サイトといいます）をご利用の際にご提供いただく お客様の氏名、住所、電話番号等の個人を特定しうる情報(以下、「個人情報」といいます) の重要性を認識し、以下の取り組みを実施して個人情報を慎重に取扱い、プライバシーの保護に努めております。

<h2>２． 個人情報の登録について</h2>
本サイトをご利用いただく場合、お客様には必要に応じて、ご自身の個人情報を入力または登録していただくことがあります。

<h2>３． 個人情報の管理について</h2>
当社は、お客様に登録して頂いた個人情報を適切、厳重に管理し、お客様の個人情報への不正なアクセスや情報の紛失、破壊、改ざんおよび漏洩等が起きぬよう、予防ならびに安全対策を講じております。


<h2>４． 個人情報の利用について</h2>
以下の何れかに該当する場合を除き、当社が個人情報を利用することはありません。<br>
<br>
(1) お客様の同意を得た場合、またはお客様の個人情報の確認の為、必要に応じてお客様の個人情報をお伺いする場合。<br>
(2) 当社からメールマガジン等で、お客様に情報を提供する場合。<br>
(3) 統計情報をサービス向上や合法的な目的のために使用する場合。<br>
(4) 当社がサービスを提供する上で、お客様へ必要かつ有益と思われるご連絡事項・情報を発信する場合。<br>

<h2>５． 個人情報の第三者への提供について</h2>
以下の何れかに該当する場合を除き、当社がお客様の個人情報を第三者に提供、開示等をすることはありません。<br>
<br>
(1) あらかじめ当社と機密保持の契約を締結している会社、金融機関等に、当社の提供するサービスに関連して、その準備、履行またはアフターサービス等に必要な範囲で提供・開示する場合。<br>
(2) 法令等により開示が求められる場合<br>
(3) お客様及び公衆の生命、健康、財産などに重大な損害が発生することを防止する為(公共の利益のため)に必要であると考えられる場合。<br>
(4) お客様の同意を得た場合。<br>
但し、お客様の同意に基づき個人情報を提供する会社には、お客様の個人情報を漏洩や再提供等しないよう、契約により義務づけ、適切な管理を実施させます。<br>


<h2>６． 15歳以下のお客様に関する個人情報の取り扱いについて</h2>
15歳以下のお客様の安全についても最大限の注意を払います。15歳以下のお客様の個人情報については、必ず保護者の方の同意の下に登録していただけますようお願いいたします。



<h2>７． 個人情報収集の目的</h2>
個人情報は、サービスの向上やお客様からのお問い合わせに対応することを目的として、必要な範囲内で収集しております。

<h2>８． その他</h2>
（1）当社が、お客様から自己の情報について開示、確認、訂正、削除等を求められた場合は、合理的な期間内にこれに対応致します。<br>
（2）本サイトは、本サイトが保有する個人情報に関して適用される法令、規範等を遵守するとともに、個人情報の取扱いに関する取り組みを適宜見直し、改善していきます。

<h2>■個人情報に関するお問い合わせ先</h2>
株式会社スカットコミュニケーションズ（サンタクロース大使館）<br>
お問い合わせ窓口<br>
〒325-0303<br>
栃木県那須郡那須町高久乙3584-4<br>
電話 0287-74-6503

<h2>～お客さまへのお願い～</h2>
当社は、本サイトからリンクしている他のウェブサイトにおけるお客様の個人情報の安全確保については、責任を負うことはできません。<br>
お客様ご自身にて、当社以外のウェブサイトにおける個人情報についての取扱基準等の内容を吟味し、取扱基準等が定められていない場合には当該ウェブサイトの担当部署・担当者に直接確認されるなどして、ご自身の個人情報の安全を確認されるようお勧めいたします。
   </div>
      

      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>

</template>

<script>
export default  {
    created: function() { this.$parent.middle = 2; }
    }
</script>

<style scoped lang="scss">


$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
    @media (max-width: ($pc)) {
        @content;
    }
}

@mixin tab {
    @media (max-width: ($tab)) {
        @content;
    }
}

@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}

.subinfotext{
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center / contain;
  @include sp{
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1{
  color: #D72E23;
  line-height: 1.7;
  margin: 2em 0;
}
.sentent_g1{
  text-align: left;
  color:#454552;
  line-height: 1.7;
  margin: 2em 0;
}
.subsent2{
  margin-bottom: 2em;
  text-align: left;
  border:solid 3px #D72E23;
  padding: 1em;
  h3{
    color: #D72E23;
    padding: 2em 0;
  }
  h4{
    color: #D72E23;
    margin : 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
}
.centeimg1{
  padding: 1em 0;
  text-align: center;
  img{
  width: 33%;
    max-width: 100%;
  }
}
.centeimg2{
  padding: 1em 0;
  text-align: center;
  img{
  width: 90%;
    max-width: 100%;
  }
}
</style>